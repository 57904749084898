import  firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

var firebaseConfig = {
    apiKey: "AIzaSyCAcrv1LKeXAPbn4kPg8P1roLgRWcoK5sc",
    authDomain: "agata-project-944f7.firebaseapp.com",
    projectId: "agata-project-944f7",
    storageBucket: "agata-project-944f7.appspot.com",
    messagingSenderId: "276475376775",
    appId: "1:276475376775:web:b669c7c3a00842fc04b422",
    measurementId: "G-G5F4NHCLZ2"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//firebase.analytics();

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export {projectStorage, projectFirestore, timestamp}