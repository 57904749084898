import React from 'react';

const Title = () => {
  return (
    <div className="title">
      <h2>AGATHA SARA</h2>
        <h5>MODEL</h5>
        <p>HEIGHT 175 | B84-W61-H92 | SHOES 38 | BROWN HAIR | BLUE EYES</p>
    </div>
  )
}

export default Title;